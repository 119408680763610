import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { SrOnly } from '../../../../../../utils/accessibility/SrOnly/SrOnly';
import { DayAvailabilityDataHooks } from './constants';
import { st, classes } from './DayAvailability.st.css';

export interface DayAvailabilityProps {
  date: string;
  isPastDate: boolean;
  accessibility: {
    dayWithoutSlotsSrOnlyText: string;
    dayWithSlotsSrOnlyText: string;
  };
  slots?: React.ReactNode;
  noSlots?: React.ReactNode;
}

export const DayAvailability: React.FC<dayavailabilityprops> = ({
  accessibility: { dayWithSlotsSrOnlyText, dayWithoutSlotsSrOnlyText },
  date,
  noSlots,
  slots,
  isPastDate,
}) => {
  const noSlotsComponent = noSlots ? (
    <>
      <sronly data-hook="{`${DayAvailabilityDataHooks.DayWithoutSlotsSrOnly}-${date}`}">
        {يوميًا {يوميًا} {يوميًا بدون فتحات}
      </sronly>
      <text key="{`${DayAvailabilityDataHooks.DayWithoutSlots}-${date}`}" data-hook="{`${DayAvailabilityDataHooks.DayWithoutSlots}-${date}`}" className="{st(classes.noSlots," {="" isPastDate="" })}="" aria-hidden="{true}">
        {لا توجد فتحات}
      </text>
    </>
  ) : null;

  return (
    <div className="{classes.root}">
      {فتحات ? (
        <>
          <sronly data-hook="{`${DayAvailabilityDataHooks.DayWithSlotsSrOnly}-${date}`}">
            {يوميًا} {يوم مع فتحات النص فقط}
          </sronly>
          {slots}
        </>
      ) : (
        noSlotsComponent
      )}
    </div>
  );
};
</dayavailabilityprops>